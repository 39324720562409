import * as React from "react"
import { graphql } from 'gatsby'
import { Container, Box, Center, Heading } from '@chakra-ui/react'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Seo from "../components/seo"
import OurLocationItem from "../components/OurLocations/OurLocationItem"


const OurLocations = ({ data }) => {

  const santaRosa = getImage(data.SantaRosaImg)
  const victor = getImage(data.VictorImg)
  const kanab = getImage(data.KanabImg)
  const twinFalls = getImage(data.TwinFallsImg)

  return (
  <>
    <Seo title="Our Locations" />
    <Container my={{sm: '8rem', md: '12rem' }} maxW="container.xl">
    <Box w={{ sm: '100%', md: '60%'}} textAlign="center" mx="auto" mb="8">
        <Heading as="h1" px="8" color="pine.300" textTransform="uppercase" fontSize="3rem" lineHeight="0.8" textAlign="center" >
          Our Locations
        </Heading>
      </Box>
      <Container maxW="container.md">
      <Box>
          <OurLocationItem 
            src={santaRosa}
            alt="Santa Rosa, CA"
            location="Santa Rosa, CA"
            description="Just 55 miles north of San Francisco, in the urban heart of wine country, you'll find a city with a cool vibe and vibrant culture ready and waiting to be explored. Santa Rosa has countless options to see, do, eat, drink, hike, bike, and explore in Sonoma County."
            listingLink="https://newwestproperties.guestybookings.com/listings?city=Santa%20Rosa"
          />
        </Box>
      <Box>
          <OurLocationItem 
            src={victor}
            alt="Victor, Idaho"
            location="Victor, ID"
            description="Located on the Western side of the Teton Mountains in Teton Valley, ID, Victor is a quaint mountain town within striking distance of all that the Teton area has to offer: Grand Teton and Yellowstone National parks, incredible wildlife, and a true Western vibe."
            listingLink="https://newwestproperties.guestybookings.com/listings?city=Victor"
          />
        </Box>
        <Box>
          <OurLocationItem 
            src={kanab}
            alt="Kanab, UT"
            location="Kanab, UT"
            description="Kanab, located in southern Utah, is just north of the Arizona state line. With an abundance of fun things to do, it’s a fantastic basecamp for Southern Utah adventures, and is a great jumping off point for Canyonlands, Bryce Canyon and Zion National Parks."
            listingLink="https://newwestproperties.guestybookings.com/listings?city=Kanab"
          />
        </Box>
        <Box>
          <OurLocationItem 
            src={twinFalls}
            alt="Twin Falls, ID"
            location="Twin Falls, ID"
            description="Twin Falls is located in southern Idaho, and is known as a gateway to Snake River Canyon, which lies just to its north. At the canyon’s eastern end, Shoshone Falls cascades over a broad series of rocks. It is also a 2 hour drive to the state capital of Boise and 3.5 hour drive to Victor."
          />
        </Box>
        </Container>
      </Container>
  </>
  )
}

export const query = graphql`
  query {
    SantaRosaImg: file(relativePath: {eq: "santa-rosa.png"}) {
      childImageSharp {
        gatsbyImageData(
          quality: 8
          layout: FULL_WIDTH
          placeholder: BLURRED
          aspectRatio: 1
        )
      }
    }
    VictorImg: file(relativePath: {eq: "victor.png"}) {
      childImageSharp {
        gatsbyImageData(
          quality: 8
          layout: FULL_WIDTH
          placeholder: BLURRED
          aspectRatio: 1
        )
      }
    }
    KanabImg: file(relativePath: {eq: "kanab.png"}) {
      childImageSharp {
        gatsbyImageData(
          quality: 8
          layout: FULL_WIDTH
          placeholder: BLURRED
          aspectRatio: 1
        )
      }
    }
    TwinFallsImg: file(relativePath: {eq: "twin-falls.png"}) {
      childImageSharp {
        gatsbyImageData(
          quality: 8
          layout: FULL_WIDTH
          placeholder: BLURRED
          aspectRatio: 1
        )
      }
    }
  }
`

export default OurLocations
